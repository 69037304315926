import Rails from "@rails/ujs"
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["form"]

  save() {
      Rails.fire(this.formTarget, 'submit')
  }
}
